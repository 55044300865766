import Select from "@/components/Select";
import { toast } from "react-hot-toast";
import api from "@/services/api";
import { useState } from "react";
import { STATUS_SPAN } from "../constants";

const CONTRACT_TITLE = {
  CDIE: "CDI en période d'essai",
  CDD: "CDD",
  CDI: "CDI sans période d'essai",
  OTHER: "Autre ou Chômage",
};

const CONTRACT_TYPES = ["CDIE", "CDD", "CDI", "OTHER"];

const ReportDetails = ({ values, report, tenant, setReport, setValues }) => {
  const [loading, setLoading] = useState(false);
  const handleSave = async () => {
    setLoading(true);
    try {
      const resReport = await api.put(`/report/${report._id}`, { ...report });
      if (resReport.ok) {
        setReport(resReport.data);
      }

      if (!["INCOMPLETED", "COMPLETED", "PROCESSING", "VERIFIED"].includes(resReport.data.status)) {
        const res = await api.put(`/scoring/${values._id}`, { ...values });
        if (res.ok) {
          setValues(res.data);
        }
      }
      toast.success("Dossier mis à jour !");
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast.error("Une erreur est survenue");
    }
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <div>
      {!["INCOMPLETED", "COMPLETED", "PROCESSING", "VERIFIED"].includes(report.status) && (
        <div className="rounded bg-white border p-6">
          <div className="grid grid-cols-2 gap-20">
            <div className="border space-y-2 flex justify-start items-center">
              <CircleScore score={values?.final_score} />
              <div className="flex flex-col justify-between gap-3">
                <label className="text-sm font-semibold">Score du dossier</label>
                <label className="text-sm mt-3">Indicateur</label>
                <div> {values?.final_score < 33 ? RISK_SPAN.HIGH : values?.final_score < 66 ? RISK_SPAN.MEDIUM : RISK_SPAN.LOW}</div>
              </div>
            </div>
            <div className="border space-y-2 flex justify-start items-center">
              <CircleScore score={values?.effort_rate} />
              <div className="flex flex-col justify-between gap-3">
                <label className="text-sm font-semibold">Taux d'effort (Solvabilité)</label>
                <label className="text-sm mt-3">Capacité locative</label>
                <label className="text-2xl font-semibold">{values?.rental_capacity?.toFixed(2)}€</label>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="rounded bg-white border p-6 grid grid-cols-2 gap-4 mt-6">
        <h2 className="text-2xl font-semibold col-span-2 text-primary">Locataire</h2>
        <div className="space-y-2">
          <label htmlFor="name" className="text-sm font-semibold">
            Nom complet
          </label>
          <input
            type="text"
            id="name"
            className="input"
            value={`${report?.user_first_name} ${report?.user_last_name}`}
            onChange={(e) => {
              const [firstName, lastName] = e.target.value.split(" ");
              setReport({
                ...report,
                user_first_name: firstName || "",
                user_last_name: lastName || "",
              });
            }}
          />
        </div>
        <div className="space-y-2">
          <label htmlFor="name" className="text-sm font-semibold">
            E-mail
          </label>
          <input
            type="text"
            id="name"
            className="input"
            value={report?.user_email}
            onChange={(e) => {
              setReport({
                ...report,
                user_email: e.target.value,
              });
            }}
          />
        </div>
        <div className="space-y-2">
          <label htmlFor="name" className="text-sm font-semibold">
            Téléphone
          </label>
          <div className="flex items-center gap-3">
            +33
            <input
              type="input"
              className="input"
              max={10}
              value={report?.user_phone}
              onChange={(e) => setReport({ ...report, user_phone: e.target.value.slice(0, 9) })}
              placeholder="6XXXXXXXX"
            />
          </div>
        </div>
        <div className="space-y-2">
          <label htmlFor="name" className="text-sm font-semibold">
            Date de naissance
          </label>
          <input
            type="date"
            id="name"
            className="input"
            value={formatDate(new Date(tenant?.cni_birth_date))}
            onChange={(e) => setTenant({ ...tenant, cni_birth_date: e.target.value })}
          />
        </div>
      </div>
      {report.has_guarantor && (
        <div className="rounded bg-white border p-6 grid grid-cols-2 gap-4 mt-6">
          <h2 className="text-2xl font-semibold col-span-2 text-primary">Garant</h2>
          <div className="space-y-2">
            <label htmlFor="name" className="text-sm font-semibold">
              Nom complet
            </label>
            <input
              type="text"
              id="name"
              className="input"
              value={`${report?.guarantor_first_name} ${report?.guarantor_last_name}`}
              onChange={(e) => {
                const [firstName, lastName] = e.target.value.split(" ");
                setReport({
                  ...report,
                  guarantor_first_name: firstName || "",
                  guarantor_last_name: lastName || "",
                });
              }}
            />
          </div>
          <div className="space-y-2">
            <label htmlFor="name" className="text-sm font-semibold">
              E-mail
            </label>
            <input
              type="text"
              id="name"
              className="input"
              value={report?.guarantor_email}
              onChange={(e) => {
                setReport({
                  ...report,
                  guarantor_email: e.target.value,
                });
              }}
            />
          </div>
          <div className="space-y-2">
            <label htmlFor="name" className="text-sm font-semibold">
              Téléphone
            </label>
            <div className="flex items-center gap-3">
              +33
              <input
                type="input"
                className="input"
                max={10}
                value={report?.guarantor_phone}
                onChange={(e) => setReport({ ...report, guarantor_phone: e.target.value.slice(0, 9) })}
                placeholder="6XXXXXXXX"
              />
            </div>
          </div>
          <div className="space-y-2">
            <label htmlFor="name" className="text-sm font-semibold">
              Date de naissance
            </label>
            <input
              type="date"
              id="name"
              className="input"
              value={formatDate(new Date(values?.cni_birth_date))}
              onChange={(e) => setValues({ ...values, cni_birth_date: e.target.value })}
            />
          </div>
        </div>
      )}
      {!["INCOMPLETED", "COMPLETED", "PROCESSING", "VERIFIED"].includes(report.status) && (
        <>
          <div className="rounded bg-white border p-6 grid grid-cols-2 gap-4 mt-6">
            <h2 className="text-2xl font-semibold col-span-2 text-primary">{`${report.has_guarantor ? "Garant" : "Locataire"} - Informations supplémentaires`}</h2>
            <div className="space-y-2">
              <label htmlFor="name" className="text-sm font-semibold">
                Revenu net mensuel
              </label>
              <input type="number" id="name" className="input" value={values?.payslip_net_salary} onChange={(e) => setValues({ ...values, payslip_net_salary: e.target.value })} />
            </div>
            <div className="space-y-2">
              <label htmlFor="name" className="text-sm font-semibold">
                Revenu fiscal de référence
              </label>
              <input
                type="number"
                id="name"
                className="input"
                value={values.tax_notice_fiscal_revenue ? values.tax_notice_fiscal_revenue : ""}
                onChange={(e) => setValues({ ...values, tax_notice_fiscal_revenue: Number(e.target.value) })}
              />
            </div>
            <div className="space-y-2">
              <label htmlFor="name" className="text-sm font-semibold">
                Ancienneté du contrat de travail
              </label>
              <input
                type="text"
                id="name"
                className="input"
                value={values?.payslip_contract_duration}
                onChange={(e) => setValues({ ...values, payslip_contract_duration: e.target.value })}
              />
            </div>
            <div className="space-y-2">
              <label htmlFor="name" className="text-sm font-semibold">
                Type de contrat de travail
              </label>
              <Select
                options={CONTRACT_TYPES}
                value={values?.payslip_contract_type}
                onChange={(value) => setValues({ ...data, payslip_contract_type: value })}
                getLabel={(type) => CONTRACT_TITLE[type]}
              />
            </div>
            <div className="space-y-2 col-span-2">
              <label htmlFor="name" className="text-sm font-semibold">
                Montant de la dernière quittance de loyer
              </label>
              <input
                type="number"
                id="name"
                className="input"
                value={values?.rent_receipt_amount?.toLocaleString("fr-FR", { style: "currency", currency: "EUR" })}
                onChange={(e) => setValues({ ...values, rent_receipt_amount: e.target.value })}
              />
            </div>
          </div>
        </>
      )}
      <div className="rounded bg-white border p-6 grid grid-cols-2 gap-4 mt-6">
        <div className="space-y-2">
          <label htmlFor="name" className="text-sm font-semibold">
            Date de dépot
          </label>
          <input type="date" id="name" className="input" value={formatDate(new Date(report?.created_at))} onChange={(e) => setReport({ ...report, created_at: e.target.value })} />
        </div>
        <div className="space-y-2">
          <label htmlFor="name" className="text-sm font-semibold">
            Statut du dossier
          </label>
          <Select
            options={["INCOMPLETED", "COMPLETED", "PROCESSING", "VERIFIED", "FINALIZED"]}
            value={report.status}
            onChange={(value) => setReport({ ...report, status: value })}
            getLabel={(status) => STATUS_SPAN[status]}
            position="left-0 bottom-full"
          />
        </div>
      </div>
      <div className="w-full flex justify-end mt-5">
        <button className="primary-button rounded-md px-6 py-2" onClick={handleSave}>
          {loading ? "Chargement..." : "Sauvegarder"}
        </button>
      </div>
    </div>
  );
};

const CircleScore = ({ score, maxScore = 100 }) => {
  const radius = 30;
  const circumference = 2 * Math.PI * radius;
  const validScore = typeof score === "number" && !isNaN(score) ? score : 0;
  const validMaxScore = typeof maxScore === "number" && !isNaN(maxScore) && maxScore !== 0 ? maxScore : 100;

  const percentage = validScore / validMaxScore;
  const strokeDashoffset = circumference * (1 - percentage);

  return (
    <div className="relative flex items-center justify-center w-44 h-44">
      <svg className="rotate-[-90deg]" width="100%" height="100%" viewBox="0 0 100 100">
        <circle cx="50" cy="50" r={radius} fill="transparent" stroke="#D9D9D9" strokeWidth="5" />
        <circle
          cx="50"
          cy="50"
          r={radius}
          fill="transparent"
          stroke="#16A349"
          strokeWidth="5"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          strokeLinecap="round"
        />
      </svg>
      <div className="absolute">
        <span className="text-3xl font-semibold">{`${Math.round(score)}%`}</span>
      </div>
    </div>
  );
};

const RISK_SPAN = {
  HIGH: (
    <span className="inline-flex items-center gap-1.5  rounded-md bg-red-100 px-3 py-1 text-xs font-medium text-red-600 border border-red-600">
      <div className={`h-2 w-2 rounded-full bg-red-400`} /> Risque élevé
    </span>
  ),
  MEDIUM: (
    <span className="inline-flex items-center gap-1.5  rounded-md bg-orange-100 px-3 py-1 text-xs font-medium text-orange-600 border border-orange-600">
      <div className={`h-2 w-2 rounded-full bg-orange-400`} /> Risque moyen
    </span>
  ),
  LOW: (
    <span className="inline-flex items-center gap-1.5  rounded-md bg-green-100 px-3 py-1 text-xs font-medium text-green-600 border border-green-600">
      <div className={`h-2 w-2 rounded-full bg-green-400`} /> Risque faible
    </span>
  ),
};

export default ReportDetails;
